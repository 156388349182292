import React from "react"
import Css from "./AboutCardsSection.module.css"


const AboutCardsSection = props => {

  let data = props.content["cards-section"]
  let bgColor = (props.bgColor)?props.bgColor:"#F5F6F7"

  let cards = []

  if (data) {
    cards = data.cards.map( (card, idx) => {

        return <div key={idx} className={Css.Card}>
   
            <img className={Css.Picture} src={require('../../images/' + card['image'])} alt="Card 1" />
            <div className={Css.CardTitle}>{card['title']}</div>
            <div className={Css.CardDescription}>{card['description']}</div>       

        </div>
    }) 
  }

  return (
    <div className={Css.MainContainer} style={{backgroundColor:bgColor}}>
        <h1 className={Css.SectionTitle}>{data.title}</h1>
        <div className={Css.CardsContainer} >{cards}</div>
    
    </div>
  )
}

export default AboutCardsSection