import React, { useState } from "react"
import Css from "./AboutTeamSection.module.css"

const AboutTeamSection = props => {
  let data = props.content["team-section"]
  let bgColor = props.bgColor ? props.bgColor : "#ffffff"

  let cards = []
  let cardsMobile = []

  const [showItemState, setShowItemState] = useState([])

  if (data) {
    // cards = data.members.map( (card, idx) => {

    //     const bgImg = {};
        
    //     bgImg.backgroundImage = ` url('${require('../../images/'+card.image)}')`
    //     bgImg.backgroundColor = card.color



    //     return <div key={idx} className={Css.Card} style={bgImg} onClick={(evt) => showInfo(idx)} role="button" aria-hidden>
    //         <div className={(showItemState[idx])? Css.ShowMoreInfo : Css.MoreInfo }>
    //             <div className={Css.Superpower}><span>Superpower:</span> {card['superpower']}</div>
    //             <div className={Css.Expertise}><span>Expertise:</span>{card['expertise']}</div>  

    cards = data.members.map((card, idx) => {
      const bgImg = {}

    bgImg.backgroundImage = ` url('${require('../../images/'+card.image)}')`
    bgImg.backgroundColor = card.color

      return (
        <div
          key={idx}
          className={Css.Card}
          style={bgImg}
          onClick={evt => showInfo(idx)}
          role="button"
          aria-hidden
        >
          <div className={showItemState[idx] ? Css.ShowMoreInfo : Css.MoreInfo}>
            <div className={Css.Superpower}>
              <span>Superpower:</span> {card["superpower"]}
            </div>
            <div className={Css.Expertise}>
              <span>Expertise:</span>
              {card["expertise"]}
            </div>
          </div>
          <div className={Css.CardTitle}>{card["name"]}</div>
          <div className={Css.CardDescription}>{card["position"]}</div>
        </div>
      )
    })
  }

  if (data) {
    // cardsMobile = data.members.map( (card, idx) => {

    //     const avatar = {};
    //     const bgColor = {};
        
    //     avatar.backgroundImage = ` url('${require('../../images/'+card.image)}')`
    //     avatar.backgroundColor = card.color
    //     bgColor.backgroundColor = card.color

    //     return <div key={idx} className={Css.CardMobile} style={bgColor}>
    
    //         <div className={Css.Avatar} style={avatar}></div>         
    //         <div className={Css.CardTitle}>{card['name']}</div>
    //         <div className={Css.CardDescription}>{card['position']}</div>
    //         <div className={Css.Superpower}><span>Superpower:</span> {card['superpower']}</div>
    //         <div className={Css.Expertise}><span>Expertise:</span>{card['expertise']}</div>       

    cardsMobile = data.members.map((card, idx) => {
      const avatar = {}
      const bgColor = {}

      avatar.backgroundImage = ` url('${require("../../images/" + card.image)}')`
      avatar.backgroundColor = card.color
      bgColor.backgroundColor = card.color

      return (
        <div key={idx} className={Css.CardMobile} style={bgColor}>
          <div className={Css.Avatar} style={avatar}></div>
          <div className={Css.CardTitle}>{card["name"]}</div>
          <div className={Css.CardDescription}>{card["position"]}</div>
          <div className={Css.Superpower}>
            <span>Superpower:</span> {card["superpower"]}
          </div>
          <div className={Css.Expertise}>
            <span>Expertise:</span>
            {card["expertise"]}
          </div>
        </div>
      )
    })
  }

  let showInfo = idx => {
    if (showItemState[idx] === undefined) {
      showItemState[idx] = false
    }

    showItemState[idx] = !showItemState[idx]

    setShowItemState([...showItemState])
  }

  return (
    <div className={Css.MainContainer} style={{backgroundColor:bgColor}}>
        <h2 className={Css.SectionTitle}>{data.title}</h2>
        <h3 className={Css.SectionSubTitle}>{data.description}</h3>
        <div className={Css.CardsContainer} >
            <div className={Css.CardFix}>
                <img className={Css.MeetUs} src={require('../../images/meet.png')} alt="Meet Us" />
            </div>
            {cards}
            <div className={Css.CardFix}>
                {/* <img className={Css.LetsTurn} src={require('../../images/letsturn.png')} alt="Let’s 
    <div className={Css.MainContainer} style={{ backgroundColor: bgColor }}>
      <h2 className={Css.SectionTitle}>{data.title}</h2>
      <h3 className={Css.SectionSubTitle}>{data.description}</h3>
      <div className={Css.CardsContainer}>
        <div className={Css.CardFix}>
          <img
            className={Css.MeetUs}
            src={require("../images/meet.png")}
            alt="Meet Us"
          />
        </div>
        {cards}
        {/* <div className={Css.CardFix}> */}
                <img className={Css.LetsTurn} src={require('../../images/letsturn.png')} alt="Let’s 
                turn users into Believers" />
            </div>
        {cardsMobile}
      </div>
    </div>
  )
}

export default AboutTeamSection
